import Vue from "vue";
import App from "./App.vue";
import BootstrapVue from "bootstrap-vue";
import VueApexCharts from "vue-apexcharts";
import Vuelidate from "vuelidate";
import VueSweetalert2 from "vue-sweetalert2";
import VueMask from "v-mask";
import * as VueGoogleMaps from "vue2-google-maps";
import VueYoutube from "vue-youtube";

import vco from "v-click-outside";

import router from "./router";
import store from "@/state/store";
import i18n from "./i18n";

import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";

import "@/assets/scss/app.scss";

import { initFirebaseBackend } from "./helpers/firebase/authUtils";
import currencyFilter from "./helpers/filters/currencyFilter";
import formatDateFilter from "./helpers/filters/formatDateFilter";
import truncateTextFilter from "./helpers/filters/truncateTextFilter";
import hasPermissionDirective from "./directives/v-has-permission";
import formatDate from "./directives/format-date";

import { configureFakeBackend } from "./helpers/fakebackend/fake-backend";

const firebaseConfig = {
    apiKey: process.env.VUE_APP_APIKEY,
    authDomain: process.env.VUE_APP_AUTHDOMAIN,
    databaseURL: process.env.VUE_APP_VUE_APP_DATABASEURL,
    projectId: process.env.VUE_APP_PROJECTId,
    storageBucket: process.env.VUE_APP_STORAGEBUCKET,
    messagingSenderId: process.env.VUE_APP_MESSAGINGSENDERID,
    appId: process.env.VUE_APP_APPId,
    measurementId: process.env.VUE_APP_MEASUREMENTID,
};

if (process.env.VUE_APP_DEFAULT_AUTH === "firebase") {
    initFirebaseBackend(firebaseConfig);
} else {
    configureFakeBackend();
}

Vue.config.productionTip = false;
Vue.use(VueYoutube);
Vue.use(BootstrapVue);
Vue.use(vco);
Vue.use(Vuelidate);
Vue.use(VueSweetalert2);
Vue.use(VueMask);
Vue.use(require("vue-chartist"));
Vue.use(VueGoogleMaps, {
    load: {
        key: "AIzaSyB5Bd8GBnVApgmFjVnEL3lvPpYZyxRXbvo",
        libraries: "places",
    },
    installComponents: true,
});
Vue.component("apexchart", VueApexCharts);
Vue.component("v-select", vSelect);
Vue.config.errorHandler = (err) => {
    /* handle error */
    console.log("Error handler");
    console.log(err);
};

//Filters
Vue.filter("currency", currencyFilter);
Vue.filter("truncateText", truncateTextFilter);
Vue.filter("formatDate", formatDateFilter);

//Directives
Vue.directive("has-permission", hasPermissionDirective);
Vue.directive("format-date", formatDate);

new Vue({
    router,
    store,
    i18n,

    data() {
        return {
            isAppReady: false,
        };
    },
    async beforeMount() {
        try {
            if (this.$store.getters["auth/loggedIn"]) {
                await this.$store.dispatch("permission/loadList");
            }
            this.isAppReady = true;
        } catch (error) {
            this.isAppReady = true;
            console.error("Error al cargar permisos:", error);
        }
    },
    mounted() {
        this.$store.watch(
            () => this.$store.getters["auth/loggedIn"],
            async (loggedIn) => {
                if (loggedIn) {
                    try {
                        await this.$store.dispatch("permission/loadList");
                    } catch (error) {
                        console.error(
                            "Error al cargar permisos tras autenticarse:",
                            error
                        );
                    }
                }
            }
        );
    },
    render(h) {
        if (this.isAppReady) {
            return h(App);
        } else {
            return h("div", { attrs: { id: "preloader" } }, [
                h("div", { attrs: { id: "status" } }, [
                    h("div", { class: "spinner" }, [
                        h("i", { class: "ri-loader-line spin-icon" }),
                    ]),
                ]),
            ]);
        }
    },
}).$mount("#app");
