import moment from "moment";

export default {
  bind(el, binding) {
    if (!binding.value) {
      el.innerText = "";
      return;
    }

    const date = moment(binding.value);
    const format = binding.arg || "DD-MM-YYYY"; // Permite cambiar el formato con argumentos
    const formattedDate = date.format(format);
    const fullDate = date.format("DD-MM-YYYY HH:mm:ss");

    el.innerHTML = `<span title="${fullDate}">${formattedDate}</span>`;
  },

  update(el, binding) {
    if (!binding.value) {
      el.innerText = "";
      return;
    }

    const date = moment(binding.value);
    const format = binding.arg || "DD-MM-YYYY";
    const formattedDate = date.format(format);
    const fullDate = date.format("DD-MM-YYYY HH:mm:ss");

    el.innerHTML = `<span title="${fullDate}">${formattedDate}</span>`;
  }
};
